import React from "react"
import Layout from "../components/layout"


export default () => (
  <Layout>
    <h4>Email: <a href="mailto:mlapeter@gmail.com">mlapeter@gmail.com</a></h4>
    <h4>Medium: <a href="https://medium.com/@mlapeter">https://medium.com/@mlapeter</a></h4>
    <h4>LinkedIn: <a href="https://www.linkedin.com/in/mlapeter/">https://www.linkedin.com/in/mlapeter</a></h4>
    <h4>GitHub: <a href="https://github.com/mlapeter/">https://github.com/mlapeter</a></h4>
    <h4>Twitter: <a href="https://twitter.com/mlapeter">https://twitter.com/mlapeter</a> </h4>
  </Layout>
)